import React,{ useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import './AlertSystemPage.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { removeAlert } from "../../redux/actions/alertAction";

function Alert(props) {
    return <MuiAlert elevation={5} variant="standard" {...props} />;
  }



const AlertSystemPage=()=>{
    const dispatch=useDispatch();
    let alertInfo = useSelector((state) => {
        return state.alert;
    });

    const [open,setOpen] =useState(true);

    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return(
        <>
            {true?
                <section >
                    {
                        alertInfo.length > 0 ?
                            <React.Fragment>
                                {
                                    alertInfo.map((alert,index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {/* <div  className={`alert alert-${alert.color} alert-dismissible animated slideInDown fixed-top m-3`}>
                                                    <button className="close">
                                                        <i className="fa fa-times-circle"/>
                                                    </button>
                                                    <small>{alert.message}</small>
                                                </div> */}
                                                {/* eslint-disable-next-line no-useless-concat */}
                                                <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={open}  onClose={handleClose}  key={"top" + "right"} style={{marginTop:(54*index)}}>
                                                    <Alert onClose={()=>{dispatch(removeAlert(alert.id))}} severity={alert.color}> {alert.message}  </Alert>                                               
                                                </Snackbar>
                                            </React.Fragment>
                                            
                                        )
                                    })
                                }

                                
                            </React.Fragment> : null
                    }
                </section>
            :
            null
            }
           
        </>
    )
}

export default AlertSystemPage;