import React, { lazy } from "react";
import { Route,Switch } from "react-router-dom";
import MobileLayout from "./Mobile/MobileLayout/MobileLayout";
import MyLocationData from "./MyLocationData";

const HomeMobilePage = lazy(() => import("./Mobile/MobileView/HomeMobilePage/HomeMobilePage"));
// const RestaurantList = lazy(() => import("./view/RestaurantList/RestaurantList"));
// const AllRestaurant = lazy(() => import("./view/AllRestaurant/AllRestaurant"));
const RestaurantDiscMobilePage = lazy(() => import("./Mobile/MobileView/RestaurantDiscMobilePage/RestaurantDiscMobilePage"));
const RestaurantDiscInfoMobilePage = lazy(() => import("./Mobile/MobileView/RestaurantDiscInfoMobilePage/RestaurantDiscInfoMobilePage"));
const UserProfileMobilePage = lazy(() => import("./Mobile/MobileView/UserProfileMobilePage/UserProfileMobilePage"));
const PreferenceDetailMobilePage = lazy(() => import("./Mobile/MobileView/PreferenceDetailMobilePage/PreferenceDetailMobilePage"));
// const Verify = lazy(() => import("./view/Signup/verify"));
const PageNotFound404MobilePage = lazy(() => import('./Mobile/MobileView/PageNotFound404MobilePage/PageNotFound404MobilePage'));

export const routesCode = [
    { path: "/", exact: true, component: HomeMobilePage },
    // { path: "/restaurant_list", exact: true, component: RestaurantList },
    // { path: "/allrestaurant", exact: true, component: AllRestaurant },
    { path: "/restaurant/:restId", exact: true, component: RestaurantDiscMobilePage },
    { path: "/restaurant_dish_info/:dishId", exact: true, component: RestaurantDiscInfoMobilePage },
    { path: "/user_detail", exact: true, component: UserProfileMobilePage },
    { path: "/preference_mobile", exact: true, component: PreferenceDetailMobilePage },

    // { path: "/verify/:token", exact: true, component: Verify }
    { path: "/404",exact: true, component: PageNotFound404MobilePage},
    { path: "*",exact: true, component: PageNotFound404MobilePage}
];

class MobileRoutes extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <MobileLayout>
                    <div>
                        <MyLocationData/>
                    </div>
                    {/* <Route  path="/" exact strict component={HomeMobilePage} /> */}
                    <Switch>
                        {routesCode.map((route, i) =>
                            <Route {...route} key={i} />)
                        }
                    </Switch>
                </MobileLayout>
            </React.Fragment>
        );
    }
}

export default MobileRoutes;
