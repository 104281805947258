import axios from "axios";
// alert("sss")
const mytoken = localStorage.getItem("access_token");

if (window.location.host === "localhost:3000") {
  //  axios.defaults.baseURL = "https://pickypigsapi.herokuapp.com";
  // axios.defaults.baseURL = "https://apps.narola.online:5003";
  // axios.defaults.baseURL = "https://eros.narola.online:5003";
  axios.defaults.baseURL = "https://api.pickypigs.com:5003";

  // axios.defaults.baseURL = "http://192.168.100.39:5003";
  // axios.defaults.baseURL = "https://localhost:5003";
} else {
  // axios.defaults.baseURL = "https://pickypigsapi.herokuapp.com";
  // axios.defaults.baseURL = "http://192.168.100.39:5003";
  // axios.defaults.baseURL = "https://apps.narola.online:5003";
  // axios.defaults.baseURL = "https://eros.narola.online:5003";
  axios.defaults.baseURL = "https://api.pickypigs.com:5003";

  //api live server
}
if (mytoken && mytoken)
  axios.defaults.headers.common = { "x-access-token": mytoken };

export default axios;


